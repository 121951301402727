.container{
    width: 100%;
}

header{
    background-color: black;
    padding: 25px;
}

.logo{
    height: 180px;
    width: 500px;
}

li > a {
    color: white;
}

figure {
    width: 100%;
    height: 150px;
}

main{
    margin-top: 50px;
}

.select-unidade{
    margin-top: 50px;
}

.form-select{
    border-radius: 10px;
    transition: all 0.5s;
}

.form-select:hover{
    cursor: pointer;
    border: 2px solid black;
    transition: all 0.5s;
}

.form-select{
    border-end-start-radius: 50px;
    border-start-start-radius: 50px;
}

.button{
    border-radius: 50px;
    transition: all 0.25s;
}

.button:hover{
    transform: scale(1.2);
    transition: all 0.4s;
}

.dadosPagamento{
    margin-bottom: 25px;
}


.btnImprimir{
    margin-bottom: 100px;
}

.btn-home{
    margin-top: 20px;
    margin-bottom: 100px;
}

.btn-danger{
    margin-top: 25px;
    margin-left: 155px;
}

@media (max-width: 767px) {
    .form-group {
      display: flex;
      flex-direction: column;
    }
    .mobile{
        display: flex;
        flex-wrap: wrap;
        border: 2px solid red;
    }
  }

  /* figure{
    background-image: url("/public/assets/logoProvisoria.jpeg");
    background-repeat: no-repeat;
    
  } */